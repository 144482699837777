import {CTDistributionCalcMethod, EspPermissions, PlanContactTypes} from '../common/enums';

export class Plan {
    planName: string;
    entityType:  string;
    entityId: number;
    entityOid: string;
    entityName:  string;
    ltfcstName: string;
    ltfcstOid: string;
    startDate:  string;
    endDate:  string;
    status: planStatus;
    entityPermission:  string;
    createdBy:  string;
    modifiedBy: string;
    modifiedTime:  string;
    permission: string;
}

export class CreatePlanRequest {
    planName: string;
    entityType: string;
    entity: any;
    forecast: any;
}

export class CreatePlanRequestV2 {
  planName: string;
  entityType: string;
  entity: any;
  forecastSource: string;
  forecast: any;
  mus?:any[];
  muset?:any;
  week1:any;
  week2:any;
  week3:any;
  week4:any;
  workflowOid?:string;
}

export class EditPlanDetailRequestDTO {
    planId: String;
    startDate: string;
    endDate: string;
    interval: string;
    planParameterLabel: string;
    editedValue: string;
    previousValue: string;
    ctOid: string;
    muOid?: string;
    muRollup?:boolean;
  }

export interface MultiEditPlanDetailRequestDTO {
  planId: String;
  editInterval: string;
  ctOid: string;
  muOid?: string;
  muRollup: boolean;
  editedData:{
    [date: string]:{
      startDate: string;
      endDate: string;
      parameters:{
        [paramName:string]:{
          editedValue: string;
          previousValue: string;
        }
      }
    }
  }
}

export interface MultiEditPlanDetailRequestDTOV2 {
  planId: String;
  editInterval: string;
  editType:string;
  muRollup: boolean;
  editedData:{
    [oid: string]: {
      [date: string]: {
        startDate: string;
        endDate: string;
        parameters: {
          [paramName: string]: {
            editedValue: string;
            previousValue: string;
          }
        }
      }
    }
  }
}

export class PlanListResponse {

    plans: Array<Plan>;
    offset: number;
    limit: number;
    sortBy: planSortBy;
    sortOrder: sortOrder;
    totalCount: number;

}

export class CTInfo{
  id:string;
  name:string;
  oid:string;
}

export class PlanDetailResponse {
    interval: string;
    planId: string;
    request: any;
    ctInfo:Array<CTInfo>;
    muInfo: Array<CTInfo>;
    entityType:string;
    selectedType:string;
    selectedOid:string;
    muRollupParams:any;
}
export class PlanSettings{
  ctDistributionCalcMethod: CTDistributionCalcMethod
  endDate:string
  entityId:number;
  entityName:string;
  ltForecastName:string;
  medianPatienceTime:any
  planContactTypes:string
  planSettingsWorkloadType:PlanContactTypes;
  startDate:string;
  userPlanPermission:EspPermissions;
}
export class PlanDetailResponseV2 {
  interval: string;
  planId: string;
  request: any;
  ctInfo:Array<CTInfo>;
  muInfo: Array<CTInfo>;
  planEntityType:string;
  muRollupParams:any;
  entityParams:[];
  multiStepWorkFlow:any;
  settings:PlanSettings;
  entitySettings:any;



}

export class MuInfo {
  oid:	string
  id:	number
  name:	string
}
export class CtStaffingData {
  ct:CTInfo;
  baseDistribution:number;
  dateDistributions:Array<any>
}
export class MuStaffingResponse {
  mus:	Array<MuInfo>;
  weekDates: Array<any>;
  muStaffingData : {
    mu:MuInfo,
    cts:Array<CtStaffingData>
  }
}

export class DiscardMuStffingResponse extends MuStaffingResponse {
}

export class EditMuDistRequestV2{
  planId:string;
  muOid:string;
  base:any;
  applyBase:boolean;
  dateDistributions:any;
  applyBaseToDates: Array<string>;
  editAction:any
}


export class DiscardMuDistRequest {
  planId: string;
  muOid: string;
  revertAction: discardMuDist;
  revertAll: boolean;
}

export class PlanConvertResponse {
  id:string;
}

export class PlanNameExists {
    planName: string;
    exists: boolean;
}

export class PlanSaveAsResponse {
    planId: string;
    planName: string;
    planStatus: planStatus;
}

export class EditEntitySettingsDto {
  planId: string;
  entityType: string;
  entityOid: string;
  interval: string;
  startingBacklog: number;
}

enum planSortBy {
    PLAN_NAME, ENTITY_TYPE, ENTITY, FORECAST_NAME, DATE_RANGE, MODIFIED_DATE
}
enum sortOrder {
    ASC, DESC
}

enum planStatus {
    PENDING, ACTIVE, FAILED
}

export enum discardMuDist {
  REVERT_DISTRIBUTIONS, RETURN_TO_PLAN
}

export enum Features{
    PLAN_CT_SETTINGS = "esp-planctsettings-esp9367",
    FTE_SETTINGS = "esp-ftehours-esp9368",
    FTE_SETTINGS_MONTH = "esp-ftemonth-esp9369"
}
export enum EntityType{
    CT = "CT", CTSET = "CTSET",BU="BU", BUSET="BUSET",EG="EG",EGSET="EGSET",MU="MU", MUSET="MUSET"
}

export class LRUCache {
  private map = new Map();
  private size: number;
  constructor(maxItems = 10) {
    this.size = maxItems;
  }

  get(key: string) {
    let item = this.map.get(key);
    if (item) {
      this.map.delete(key);
      this.map.set(key, item);
    }
    return item;
  }

  set(key: string, val: any) {
    if (this.map.has(key)) this.map.delete(key);
    else if (this.map.size == this.size) this.map.delete(this.leastRecent());
    this.map.set(key, val);
  }

  leastRecent() {
    return this.map.keys().next().value;
  }

  asString() {
    return JSON.stringify(Array.from(this.map.entries()));
  }

  // parameter: double array string without duplicate keys
  init(jsonStr:string) {
    this.map = new Map(JSON.parse(jsonStr));
    while(this.map.size > this.size) {
      this.map.delete(this.leastRecent());
    }
  }


}

export class SelectedMU {
  oid:string;
  mu:any;
  isExpanded:boolean;
  constructor(oid, mu, isExpanded = true){
    this.oid = oid;
    this.mu = mu;
    this.isExpanded = isExpanded;
  }
}


export class CachedMU {
  selectedMUList: SelectedMU[];
  rollupExpanded: boolean;
  constructor(selectedMUs: SelectedMU[], rollupExpanded = true){
    this.selectedMUList = selectedMUs;
    this.rollupExpanded = rollupExpanded;
  }
}

import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {ValidationResult, WfmTextboxComponent} from '../../../components/wfm-textbox/wfm-textbox.component';
import {SelectItem} from '../../../components/wfm-select/wfm-select.component';
import {DatePipe, FormatWidth, getLocaleDateFormat} from '@angular/common';
import {PlanHelper} from '../../../helpers/plan-helper';
import {Store} from '@ngrx/store';
import {Actions, ofType} from '@ngrx/effects';
import {Subject} from 'rxjs';
import {AppActionTypes, CheckPlanNameExists} from '../../../store/actions';
import {takeUntil} from 'rxjs/operators';
import * as appActions from '../../../store/actions';
import {ScrollerOptions} from 'primeng/api';

@Component({
  selector: 'create-plan-cxone',
  templateUrl: './create-plan-cxone.component.html',
  styleUrl: './create-plan-cxone.component.scss'
})
export class CreatePlanCXoneComponent implements OnInit, AfterViewInit, OnDestroy {
  unsubscribe$: Subject<void> = new Subject();

  enableCreatePlan: boolean = true;
  planModel = {
    planName: null,
    forecastSource: null
  };

  forecastSourceList: Array<SelectItem> = new Array<SelectItem>();
  forecastSourceKeys: string[] = [
    "plan.forecast.source.cxone.wfm",
    "plan.forecast.source.imp"
  ];
  selectedForecastSource: SelectItem;

  startDayOfWeekKeys = [
    "plan.start.day.of.week.sunday",
    "plan.start.day.of.week.monday",
    "plan.start.day.of.week.tuesday",
    "plan.start.day.of.week.wednesday",
    "plan.start.day.of.week.thursday",
    "plan.start.day.of.week.friday",
    "plan.start.day.of.week.saturday"
  ];
  startDayOfWeekList: string[] = [];
  _startDayOfWeekListLoading: boolean = false;
  _startDayOfWeekDisabled: boolean;
  selectedStartDayOfWeek: SelectItem;

  _forecastDisabled: boolean;
  _currForecastList: Array<SelectItem> = new Array<SelectItem>();
  _forecastListLoading: boolean = false;
  selectedForecast: SelectItem;

  selectedWemSkills = [];
  wemSkillsList: any;

  profileList: string[] = [];
  selectedProfile: SelectItem;
  _profileDisabled: boolean;
  _profileListLoading: boolean = false;

  schedulingUnitList: any;
  selectedSUs = [];

  checkingUniquePlanName = false;
  virtualScrollOptions: ScrollerOptions = {
    autoSize: false,
    scrollHeight: '120px'
  }

  mockSchedulingUnitList = {
    schedulingUnits: [
      {
        "oid": "oid-1",
        "name": "scheduling-unit-1"
      },
      {
        "oid": "oid-2",
        "name": "scheduling-unit-2"
      },
      {
        "oid": "oid-3",
        "name": "scheduling-unit-3"
      },
      {
        "oid": "oid-4",
        "name": "scheduling-unit-4"
      },
      {
        "oid": "oid-5",
        "name": "scheduling-unit-5"
      },
      {
        "oid": "oid-6",
        "name": "scheduling-unit-6"
      },
      {
        "oid": "oid-7",
        "name": "scheduling-unit-7"
      }
    ]
  }

  mockProfileList = {
    profiles: [
      {
        "oid": "oid-1",
        "name": "profile-1"
      },
      {
        "oid": "oid-2",
        "name": "profile-2"
      },
      {
        "oid": "oid-3",
        "name": "profile-3"
      },
      {
        "oid": "oid-4",
        "name": "profile-4"
      },
      {
        "oid": "oid-5",
        "name": "profile-5"
      },
      {
        "oid": "oid-6",
        "name": "profile-6"
      },
      {
        "oid": "oid-7",
        "name": "profile-7"
      }
    ]
  }

  mockWemSkillsList = {
    wemSkills: [
      {
        "oid": "oid-1",
        "name": "skill-1"
      },
      {
        "oid": "oid-2",
        "name": "skill-2"
      },
      {
        "oid": "oid-3",
        "name": "skill-3"
      },
      {
        "oid": "oid-4",
        "name": "skill-4"
      },
      {
        "oid": "oid-5",
        "name": "skill-5"
      },
      {
        "oid": "oid-6",
        "name": "skill-6"
      },
      {
        "oid": "oid-7",
        "name": "skill-7"
      }
    ]
  };

  @ViewChild("planName") planNameEl: WfmTextboxComponent;

  constructor(public activeModal: NgbActiveModal,
              private translate: TranslateService,
              private store: Store<any>,
              private action$: Actions,
              private datePipe: DatePipe) {
  }

  ngOnInit(): void {
    this.initHandlers();

    this.forecastSourceList = this.forecastSourceKeys.map(key => {
      const value = key === "plan.forecast.source.cxone.wfm" ? "wfm" : "import";
      const item: SelectItem = {label: this.translate.instant(key), value: value, object: key};
      return item;
    });

    // set the default forecast source
    // this.planModel.forecastSource = this.forecastSourceList[0];

    this.populateWemSkillsList(this.mockWemSkillsList);

    this.populateSchedulingUnitList(this.mockSchedulingUnitList);

    this.startDayOfWeekList = this.startDayOfWeekKeys.map(key => {
      return this.translate.instant(key);
    });

    this.populateProfileList(this.mockProfileList);
  }

  ngAfterViewInit(): void {
    this.planNameEl.validators.push(this.planNameValidator.bind(this));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initHandlers() {
    this.action$
      .pipe(ofType(AppActionTypes.CheckPlanNameExistsReceived), takeUntil(this.unsubscribe$))
      .subscribe(this.planNameExistsReceiveHandler.bind(this));

    this.action$
      .pipe(ofType(AppActionTypes.CheckPlanNameExistsFailed), takeUntil(this.unsubscribe$))
      .subscribe(this.planNameExistsFailedHandler.bind(this));

    this.action$
      .pipe(ofType(AppActionTypes.GetLongTermForecastsSuccess), takeUntil(this.unsubscribe$))
      .subscribe(this.getLongTermForecastsSuccessHandler.bind(this));

    this.action$
      .pipe(ofType(AppActionTypes.GetLongTermForecastsFailed), takeUntil(this.unsubscribe$))
      .subscribe(this.getLongTermForecastsFailedHandler.bind(this));
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  createPlan() {
    // TODO: remove this when the API is ready
    this.dismiss();
  }

  setPlanName(name: string) {
    this.planModel.planName = name;
    this.resetForm();
  }

  resetForm() {
    if (this.planModel?.planName?.trim().length <= 0) {
      this.selectedForecastSource = null;
      this.selectedForecast = null;
    }
  }

  hasValidPlanName() {
    return !this.planNameEl?.hasClientError && this.hasPlanNameWithLength();
  }

  hasPlanNameWithLength() {
    return this.planModel?.planName?.trim().length > 0;
  }

  checkPlanNameExists(event: any) {
    if (this.hasValidPlanName()) {
      this.checkingUniquePlanName = true;
      const planName = event.target.value;
      this.store.dispatch(new CheckPlanNameExists({ planName: planName }));
    }
  }

  updateForecastSource($event: SelectItem) {
    this.selectedForecastSource = $event;
    // console.log('CreatePlanCXoneComponent selectedForecastSource:', this.selectedForecastSource);
    this._forecastListLoading = true;
    this.store.dispatch(new appActions.GetLongTermForecasts(this.selectedForecastSource));
  }

  updateForecast($event: any) {
    console.log('CreatePlanCXoneComponent updateForecast:', $event);
  }

  get forecastList() {
    return this._currForecastList;
  }

  loadForecastList(forecastData: any) {
    this._currForecastList = new Array<SelectItem>();

    let dateShortFormat = "M/d/yy";
    if (this.translate.currentLang) {
      dateShortFormat = getLocaleDateFormat(this.translate.currentLang, FormatWidth.Short);
    }

    forecastData.forecastEntries.forEach(entry => {
      let startDate = (entry.startDate !== null) ? this.datePipe.transform(new Date(entry.startDate), dateShortFormat, "UTC +0000") : "";
      let endDate = (entry.endDate !== null) ? this.datePipe.transform(new Date(entry.endDate), dateShortFormat, "UTC +0000") : "";
      let fcLabel = this.getLTFLabelForAnElement(entry.forecastName, startDate, endDate);
      const item: SelectItem = {label: fcLabel, value: entry.oid, object: entry};
      this._currForecastList.push(item);
    });
  }

  getLTFLabelForAnElement(name: string, startDate: string, endDate: string): string {
    let fcLabel = name + ` (${startDate}`;
    if (startDate !== "" && endDate !== "") {
      fcLabel = fcLabel + " - ";
    }
    return fcLabel + `${endDate})`;
  }

  updateStartDayOfWeek($event: any) {
    console.log('CreatePlanCXoneComponent updateStartDayOfWeek:', $event);
  }

  populateSchedulingUnitList(scheduleUnitData: any) {
    // Need to make a deep copy of the scheduling unit data to avoid modifying the original data
    // let schedListOrig = JSON.parse(JSON.stringify(scheduleUnitData.schedulingUnits));

    this.schedulingUnitList = scheduleUnitData.schedulingUnits?.map((unit: any) => {
      let isSelected = false;
      return {
        value: {
          ...unit,
          label: unit.name
        },
        isSelected
      }
    });
  }

  updateSelectedSUs(event: any) {
    this.schedulingUnitList.forEach(su => {
      // su.isSelected = this.selectedSUs.indexOf(su.value) != -1;
      su.isSelected = this.selectedSUs.some(selected => selected.oid === su.value.oid);
    });
  }

  updateProfile($event: any) {
    console.log('CreatePlanCXoneComponent updateProfile:', $event);
  }

  populateProfileList(profileData: any) {
    this.profileList = profileData.profiles.map((profile: any) => {
      return profile.name;
    });
  }

  populateWemSkillsList(wemSkillsData: any) {
    // Need to make a deep copy of the WEM skills data to avoid modifying the original data
    // let wemSkillsListOrig = JSON.parse(JSON.stringify(wemSkillsData.wemSkills));

    this.wemSkillsList = wemSkillsData.wemSkills?.map((skill: any) => {
      let isSelected = true;
      let isReadOnly = true;
      return {
        value: {
          ...skill,
          label: skill.name,
          disabled: true
        },
        isSelected,
        isReadOnly
      }
    });
  }

  planNameValidator(name: string) {
    if (name.length < 1) {
      return new ValidationResult(this.translate.instant("field.required.msg"));
    } else if (!PlanHelper.planNameRegEx.test(name)) {
      return new ValidationResult(this.translate.instant("plan.name.invalid"));
    } else {
      return new ValidationResult("");
    }
  }

  planNameExistsReceiveHandler(action: any) {
    this.checkingUniquePlanName = false;
    if (action.payload.exists === true) {
      this.planNameEl.serverSideErrMsgs.push(this.translate.instant("plan.not.unique"));
    } else {
      this.planNameEl.serverSideErrMsgs = this.planNameEl.serverSideErrMsgs.filter(msg => msg != this.translate.instant("plan.not.unique"));
    }
  }

  planNameExistsFailedHandler(action: any) {
    this.checkingUniquePlanName = false;
    this.store.dispatch(new appActions.UnrecoverableError(action));
    this.dismiss();
  }

  getLongTermForecastsSuccessHandler(action: any) {
    this._forecastListLoading = false;
    this.loadForecastList(action.payload);
  }

  getLongTermForecastsFailedHandler(action: any) {
    this._forecastListLoading = false;
    this.store.dispatch(new appActions.UnrecoverableError(action));
    this.dismiss();
  }
}
